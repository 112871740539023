import blockModel from 'src/assets/CesiumIcons/BlockModel.png';
import defaultObject from 'src/assets/CesiumIcons/DefaultObject.png';
import designModelRendered from 'src/assets/CesiumIcons/DesignModelRendered.png';
import drillHoles from 'src/assets/CesiumIcons/Drillhole.png';
import geologicalModel from 'src/assets/CesiumIcons/GeologicalModel.png';
import grid2D from 'src/assets/CesiumIcons/Grid2D.png';
import lineations from 'src/assets/CesiumIcons/Lineations.png';
import lineSegments from 'src/assets/CesiumIcons/LineSegments.png';
import locations from 'src/assets/CesiumIcons/Locations.png';
import mergedPointset from 'src/assets/CesiumIcons/MergedPointset.png';
import mesh from 'src/assets/CesiumIcons/Mesh.png';
import tensorGridBlocktree from 'src/assets/CesiumIcons/TensorGridBlockTree.png';
import topography from 'src/assets/CesiumIcons/Topography.png';
import transformValues from 'src/assets/CesiumIcons/TransformValues.png';
import variogram from 'src/assets/CesiumIcons/Variogram.png';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

export const setImage = (schemaName: string): any => {
    const schemaId = extractSchema(schemaName);
    switch (schemaId) {
        case Schemas.BlockSyncReferenceSchema:
            return blockModel;
        case Schemas.DesignGeometrySchema:
            return designModelRendered;
        case Schemas.DownholeCollectionSchema:
            return drillHoles;
        case Schemas.DownholeIntervalsSchema:
            return drillHoles;
        case Schemas.IntervalDownholesSchema:
            return drillHoles;
        case Schemas.GeologicalModelMeshesSchema:
            return geologicalModel;
        case Schemas.GeophysicalRecords1DSchema:
            return geologicalModel;
        case Schemas.Regular2DGridSchema:
            return grid2D;
        case Schemas.Regular3DGridSchema:
            return grid2D;
        case Schemas.RegularMasked3DGridSchema:
            return grid2D;
        case Schemas.UnstructuredGridSchema:
            return grid2D;
        case Schemas.UnstructuredHexGridSchema:
            return grid2D;
        case Schemas.UnstructuredQuadGridSchema:
            return grid2D;
        case Schemas.UnstructuredTetGridSchema:
            return grid2D;
        case Schemas.LineSegmentsSchema:
            return lineSegments;
        case Schemas.PlanarDataPointsetSchema:
            return mergedPointset;
        case Schemas.PointsetSchema:
            return mergedPointset;
        case Schemas.LocalEllipsoidsSchema:
            return topography;
        case Schemas.GlobalEllipsoidSchema:
            return topography;
        case Schemas.LineationsDataPointsetSchema:
            return lineations;
        case Schemas.ExploratoryLocationSchema:
            return locations;
        case Schemas.TriangleMeshSchema:
            return mesh;
        case Schemas.Tensor2DGridSchema:
            return tensorGridBlocktree;
        case Schemas.Tensor3DGridSchema:
            return tensorGridBlocktree;
        case Schemas.NonParametricContinuousCumulativeDistributionSchema:
            return transformValues;
        case Schemas.VariogramSchema:
            return variogram;
        // Add more cases as needed
        default:
            return defaultObject;
    }
};
