import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import {
    useDeleteObjectsByIdMutation,
    useEnhancedUpdateObjectByIdMutation,
} from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import ListItem from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, type ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';
import { usePersistedState } from 'src/hooks/usePersistedState';
import {
    RECYCLE_DIALOG_CANCEL,
    RECYCLE_DIALOG_CONFIRM,
    RECYCLE_DIALOG_CONTENT,
    RECYCLE_DIALOG_TITLE,
    RECYCLE_DIALOG_WARNING,
    RECYCLE_TOAST_FAILURE,
    RECYCLE_TOAST_SUCCESS,
    RESTORE_OBJECT_TOAST_FAILURE,
    RESTORE_OBJECT_TOAST_SUCCESS,
    UNDO,
    VIEW_RECYCLED_OBJECTS,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { objectDefinition } from './FieldRowDefinitions';
import { ObjectActions } from './ObjectActions';
import type { ListedObjectDisplay } from './utils';

type Actions = {
    key: string;
    action: ReactNode;
};

export type NotificationContent = {
    message: string;
    actions?: Actions[];
};

interface ConfirmRecycleDialogProps {
    open: boolean;
    onClose: (confirm: boolean) => void;
    object: ListedObjectDisplay | OrgListedObject;
}

function ConfirmRecycleDialog({ open, onClose, object }: ConfirmRecycleDialogProps) {
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle automation-id="dialog-title">{RECYCLE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText automation-id="dialog-description">
                    {`${RECYCLE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                    <br />
                    {RECYCLE_DIALOG_WARNING}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    autoFocus
                    color="secondary"
                    variant="outlined"
                    onClick={() => onClose(false)}
                    automation-id="dialog-cancel-button"
                >
                    {RECYCLE_DIALOG_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onClose(true)}
                    automation-id="dialog-confirm-button"
                >
                    {RECYCLE_DIALOG_CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface ObjectRowProps {
    object: ListedObjectDisplay | OrgListedObject;
    displayEmptyActions?: boolean;
}

interface SnackbarActionsProps {
    objectID: string;
    orgId: string;
    workspaceId: string;
    handleClose: () => void;
}

function SnackbarActions({ objectID, orgId, workspaceId, handleClose }: SnackbarActionsProps) {
    const { addMessage } = useMessagesContext();
    const [restoreObject] = useEnhancedUpdateObjectByIdMutation();
    const navigate = useNavigate();
    const handleRestoreObject = async () => {
        const response = await restoreObject({
            orgId,
            workspaceId,
            objectId: objectID,
            deleted: false,
            geoscienceObject: null,
        });
        if (response.error) {
            addMessage({
                message: RESTORE_OBJECT_TOAST_FAILURE,
                severity: 'error',
            });
        } else {
            addMessage({
                message: RESTORE_OBJECT_TOAST_SUCCESS,
                severity: 'success',
            });
        }
        handleClose();
    };
    return (
        <>
            <Button
                color="secondary"
                size="small"
                onClick={handleRestoreObject}
                sx={{ textDecoration: 'underline' }}
            >
                {UNDO}
            </Button>
            <Button
                color="secondary"
                size="small"
                onClick={() => navigate('../recyclebin')}
                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
            >
                {VIEW_RECYCLED_OBJECTS}
            </Button>
        </>
    );
}

export function ObjectRow({ object, displayEmptyActions = false }: ObjectRowProps) {
    const { setSnackBar, handleClose } = useSnackBarContext();
    const [recycleObject] = useDeleteObjectsByIdMutation();
    const [id, setID] = usePersistedState('id');
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const [recycleOpen, setRecycleOpen] = useState(false);
    const params = useParams();

    const isSelected = id === object?.object_id;

    const handleRowClick = () => {
        if (isSelected) {
            setID('');
        } else {
            setID(object?.object_id);
        }
    };
    const onRecycle = async (confirm: boolean | null) => {
        if (confirm) {
            const response = await recycleObject({
                objectId: object?.object_id,
                orgId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
            });
            if (response.error) {
                setSnackBar(RECYCLE_TOAST_FAILURE, 'error', null);
            } else {
                setSnackBar(
                    RECYCLE_TOAST_SUCCESS,
                    'success',
                    <SnackbarActions
                        objectID={object?.object_id}
                        orgId={getOrgUuidFromParams(params)}
                        workspaceId={getSelectedWorkspaceFromParams(params)}
                        handleClose={handleClose}
                    />,
                );
            }
        }

        setRecycleOpen(false);
        searchParams.delete('id');
        setSearchParams(searchParams);
    };
    return (
        <>
            <ListItem
                item={object}
                automation-id="row"
                selected={isSelected}
                clickHandler={handleRowClick}
                fields={objectDefinition}
                displayEmptyActions={displayEmptyActions}
                actions={<ObjectActions object={object} onRecycle={() => setRecycleOpen(true)} />}
            />
            <ConfirmRecycleDialog open={recycleOpen} onClose={onRecycle} object={object} />
        </>
    );
}
