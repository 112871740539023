import { Accordion } from '@local/web-design-system/dist/components/Accordion';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { FolderIcon } from '@local/web-design-system/dist/icons/Files/FolderIcon';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getObjectTreeExpandedById,
    getTreeItemById,
    selectionListScenePanel,
} from 'src/store/visualization/selectors';
import { collapseTreeItem, expandTreeItem } from 'src/store/visualization/visualizationSlice';
import { ID_PARAM, ROOT_TREE_ID } from 'src/strings';
import { useDrag } from 'src/visualization/context/hooks/useDragAndDrop';
import { useQueue } from 'src/visualization/context/hooks/useQueue';
import { generateProjectTree } from 'src/visualization/utils/generateProjectTree';

import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { CONCURRENCE_VALUE } from './ProjectTree.constants';
import { useStyles } from './ProjectTreePanel.styles';
import type { ObjectPanelProps, ProjectTreeProps } from './ProjectTreePanel.types';

export function SkeletonObjectsPanelContents() {
    return (
        <Grid>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel({ data }: ObjectPanelProps) {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();

    const { enqueue } = useQueue(CONCURRENCE_VALUE);

    useEffect(() => {
        generateProjectTree({ objects: data, enqueue, dispatch });
    }, [data.length]);

    const treeItem = useAppSelector(getTreeItemById(ROOT_TREE_ID));

    return useMemo(
        () => (
            <Grid item xs className={classes.contentsContainer}>
                {treeItem?.children?.map((childItemId: string) => (
                    <ProjectTree key={childItemId} treeItemId={childItemId} data={data} />
                ))}
            </Grid>
        ),
        [treeItem?.children, data.length],
    );
}

function ProjectTree({ treeItemId, data }: ProjectTreeProps) {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const { onDragStart, onDragEnd } = useDrag(treeItemId);
    const isExpanded = useAppSelector(getObjectTreeExpandedById(treeItemId));

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID_PARAM) ?? '';
    const treeItem = useAppSelector(getTreeItemById(treeItemId));

    useEffect(() => {
        if (
            (objectIdQueryParam === treeItemId && treeItem) ||
            (treeItem &&
                treeItem.treeId.includes(objectIdQueryParam) &&
                objectIdQueryParam.length > 0)
        ) {
            dispatch(expandTreeItem(treeItem.treeId));
        }
    }, []);

    const treeItemRef = useRef<HTMLDivElement>(null);
    const selectedTreeItemIds = useAppSelector(selectionListScenePanel);
    useEffect(() => {
        if (selectedTreeItemIds[0]?.includes(':')) {
            const nestedTreeItemIds = selectedTreeItemIds[0].split(':');
            nestedTreeItemIds.forEach((_, i) => {
                const folderId = nestedTreeItemIds.slice(0, nestedTreeItemIds.length - i).join(':');
                dispatch(expandTreeItem(folderId));
            });
        }

        if (selectedTreeItemIds.includes(treeItemId)) {
            treeItemRef.current?.scrollIntoView();
        }
    }, [selectedTreeItemIds.includes(treeItemId)]);

    const memoizedContent = useMemo(() => {
        if (treeItem === undefined) return null;
        if (treeItem.children === undefined) {
            return <ObjectListItemControl key={treeItem.treeId} {...treeItem} ref={treeItemRef} />;
        }

        return (
            <Grid
                container
                className={classnames(classes.folderContainer, {
                    [classes.folderDisabled]: !treeItem.children?.length,
                })}
            >
                <Grid item xs>
                    <Accordion
                        mountOnEnter
                        icon={<FolderIcon fontSize="small" />}
                        title={treeItem.name}
                        expanded={isExpanded}
                        draggableProps={{
                            draggable: true,
                            onDragStart,
                            onDragEnd,
                        }}
                        onChange={(_, toExpand) => {
                            if (toExpand) {
                                dispatch(expandTreeItem(treeItem.treeId));
                            } else {
                                dispatch(collapseTreeItem(treeItem.treeId));
                            }
                        }}
                    >
                        {treeItem.children?.map((childItemId: string) => (
                            <ProjectTree key={childItemId} treeItemId={childItemId} data={data} />
                        ))}
                    </Accordion>
                </Grid>
            </Grid>
        );
    }, [treeItem?.children, isExpanded, treeItem?.treeId, treeItem?.name, data.length]);

    return memoizedContent;
}
