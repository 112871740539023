import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics';
import Copy from '@local/web-design-system-2/dist/icons/Copy';
import Delete from '@local/web-design-system-2/dist/icons/Delete';
import Download from '@local/web-design-system-2/dist/icons/Download';
import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useDeleteFileByIdMutation,
    useLazyGetFileByIdQuery,
} from 'src/apiClients/enhancedFileMiddleware';
import {
    useUpdateFileByIdMutation,
    type FileVersionResponse,
    type ListFile,
} from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { usePersistedState } from 'src/hooks/usePersistedState';
import {
    COPIED_TO_CLIPBOARD,
    COPY,
    RECYCLE,
    DELETE_FILE_CANCEL,
    DELETE_FILE_CONFIRM,
    DELETE_FILE_DIALOG_CONTENT,
    DELETE_FILE_DIALOG_TITLE,
    DELETE_FILE_FAILED,
    DELETE_FILE_SUCCESS,
    DOWNLOAD,
    DOWNLOAD_FILE_FAILED,
    RESTORE_FILE_TOAST_FAILURE,
    RESTORE_FILE_TOAST_SUCCESS,
    UNDO,
    VIEW_RECYCLED_FILES,
    DELETE_FILE_DIALOG_WARNING,
} from 'src/strings';

import { useStyles } from './FileActions.styles';

interface ActionProps {
    iconOnly?: boolean;
    onClick?: () => void;
}

function DownloadAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();

    if (iconOnly && onClick) {
        return (
            <Button
                onClick={onClick}
                automation-id="download-button"
                variant="outlined"
                color="secondary"
                fullWidth
                startIcon={<Download />}
            >
                <Typography variant="body1">{DOWNLOAD}</Typography>
            </Button>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Download viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{DOWNLOAD}</Typography>
            </Grid>
        </Grid>
    );
}

function DeleteAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();
    const { workspaceUserRole } = useWorkspaceContext();

    const disabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (iconOnly && onClick) {
        return (
            <Button
                onClick={onClick}
                automation-id="delete-button"
                variant="outlined"
                color="secondary"
                fullWidth
                startIcon={<Delete />}
                disabled={disabled}
            >
                <Typography variant="body1">{RECYCLE}</Typography>
            </Button>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Delete viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{RECYCLE}</Typography>
            </Grid>
        </Grid>
    );
}

function CopyAction() {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5}>
            <Grid item>
                <Copy viewBox="0 0 24 20" />
            </Grid>
            <Grid item>
                <Typography className={classes.actionText}>{COPY}</Typography>
            </Grid>
        </Grid>
    );
}

interface ConfirmDeleteDialogProps {
    open: boolean;
    file: Pick<ListFile, 'name'>;
    onClose: (confirm: boolean) => void;
}

// TODO Style this correctly, potentially make generic and move to WDS2?
function ConfirmDeleteDialog({ open, file, onClose }: ConfirmDeleteDialogProps) {
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle automation-id="dialog-title">{DELETE_FILE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText automation-id="dialog-description">
                    {`${DELETE_FILE_DIALOG_CONTENT} "${file.name}".`}
                    <br />
                    {DELETE_FILE_DIALOG_WARNING}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="secondary"
                    variant="outlined"
                    onClick={() => onClose(false)}
                    automation-id="dialog-cancel-button"
                >
                    {DELETE_FILE_CANCEL}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onClose(true)}
                    automation-id="dialog-confirm-button"
                >
                    {DELETE_FILE_CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface Props {
    file: Pick<ListFile, 'file_id' | 'name' | 'version_id'>;
    /** If provided, will be used for context when issuing download or delete queries */
    version?: FileVersionResponse;
    /** Whether to display icons, otherwise will display a dropdown menu of options */
    icons?: boolean;
}

interface SnackbarActionsProps {
    fileId: string;
    orgId: string;
    workspaceId: string;
    handleCloseSnackBar: () => void;
}

function SnackbarActions({
    fileId,
    orgId,
    workspaceId,
    handleCloseSnackBar,
}: SnackbarActionsProps) {
    const { addMessage } = useMessagesContext();
    const [restoreFile] = useUpdateFileByIdMutation();
    const navigate = useNavigate();
    const handleRestoreFile = async () => {
        const response = await restoreFile({
            organisationId: orgId,
            workspaceId,
            fileId,
            deleted: false,
        });
        if (response.error) {
            addMessage({
                message: RESTORE_FILE_TOAST_FAILURE,
                severity: 'error',
            });
        } else {
            addMessage({
                message: RESTORE_FILE_TOAST_SUCCESS,
                severity: 'success',
            });
        }
        handleCloseSnackBar();
    };
    return (
        <>
            <Button
                color="secondary"
                size="small"
                onClick={handleRestoreFile}
                sx={{ textDecoration: 'underline' }}
            >
                {UNDO}
            </Button>
            <Button
                color="secondary"
                size="small"
                onClick={() => navigate('../recyclebin')}
                sx={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}
            >
                {VIEW_RECYCLED_FILES}
            </Button>
        </>
    );
}

export function FileActions({ file, version, icons }: Props) {
    const { classes } = useStyles();
    const { setSnackBar, handleClose: handleCloseSnackBar } = useSnackBarContext();
    const params = useParams();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const { workspaceUserRole } = useWorkspaceContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fetchFile, { isLoading: isDownloading }] = useLazyGetFileByIdQuery();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileByIdMutation();
    const [, setID] = usePersistedState('id');

    const isDisabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    const handleDownload = async () => {
        handleClose();
        const { data, isError, error } = await fetchFile({
            organisationId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            fileId: file.file_id,
            versionId: version?.version_id,
        });

        if (isError) {
            trackError('Error downloading file', JSON.stringify(error));
            setSnackBar(DOWNLOAD_FILE_FAILED, 'error', null);
            return;
        }

        if (!data) {
            return;
        }

        // When the download button is clicked, we fetch the file by ID from the API, which gives us the
        // download link. This code is to automatically trigger that download link, instead of presenting the
        // user with another download button to click again.
        const downloadLink = document.createElement('a');
        downloadLink.href = data.download;
        downloadLink.download = file.name;
        downloadLink.click();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(version!.version_id);
        setSnackBar(COPIED_TO_CLIPBOARD, 'success', null);
        handleClose();
    };

    const openDeleteDialog = () => {
        handleClose();
        setDeleteDialogOpen(true);
    };

    const handleDelete = async (isConfirmed: boolean | null) => {
        setDeleteDialogOpen(false);
        setID(undefined);

        if (!isConfirmed || isDisabled) {
            return;
        }
        try {
            await deleteFile({
                organisationId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
                fileId: file.file_id,
            }).unwrap();

            setSnackBar(
                DELETE_FILE_SUCCESS,
                'success',
                <SnackbarActions
                    fileId={file?.file_id}
                    orgId={getOrgUuidFromParams(params)}
                    workspaceId={getSelectedWorkspaceFromParams(params)}
                    handleCloseSnackBar={handleCloseSnackBar}
                />,
            );

            // todo usePersistedState should be able to do this but it isn't working, investigate this
            /** @see https://seequent.atlassian.net/browse/CENPLAT-19894 */
            searchParams.delete('id');
            setSearchParams(searchParams);
        } catch (error) {
            trackError('Error deleting file', JSON.stringify(error));
            setSnackBar(DELETE_FILE_FAILED, 'error', null);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isDeleting || isDownloading) {
        if (icons) {
            return (
                <div className={classes.icons}>
                    <Skeleton variant="circular" width={38} height={38} />
                </div>
            );
        }
        return <Skeleton variant="circular" width={38} height={38} />;
    }

    if (icons) {
        // Render an inline list of icon buttons only
        return (
            <>
                {/* <div className={classes.icons}>
                    <DownloadAction onClick={handleDownload} iconOnly />
                    {!version && (
                        <DeleteAction onClick={() => setDeleteDialogOpen(true)} iconOnly />
                    )}
                    {version && <CopyAction />}
                </div>
                <ConfirmDeleteDialog open={deleteDialogOpen} onClose={handleDelete} /> */}
                <Stack direction="row" spacing={2}>
                    <DownloadAction onClick={handleDownload} iconOnly />
                    {!version && (
                        <DeleteAction onClick={() => setDeleteDialogOpen(true)} iconOnly />
                    )}
                    {version && <CopyAction />}
                    <ConfirmDeleteDialog
                        open={deleteDialogOpen}
                        file={file}
                        onClose={handleDelete}
                    />
                </Stack>
            </>
        );
    }

    // Render a menu with dropdown options
    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton size="large" onClick={handleClick} automation-id="overflow-icon">
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleDownload} automation-id="menu-menu-item-download-file">
                    <DownloadAction />
                </MenuItem>
                {!version && (
                    <div
                        aria-hidden
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                        }}
                    >
                        <MenuItem
                            disabled={isDisabled}
                            onClick={openDeleteDialog}
                            automation-id="menu-menu-item-delete-file"
                        >
                            <DeleteAction />
                        </MenuItem>
                    </div>
                )}
                {version && (
                    <MenuItem
                        onClick={handleCopy}
                        disabled={isDisabled}
                        automation-id="menu-menu-item-copy-file-id"
                    >
                        <CopyAction />
                    </MenuItem>
                )}
            </Menu>
            <ConfirmDeleteDialog open={deleteDialogOpen} file={file} onClose={handleDelete} />
        </>
    );
}
